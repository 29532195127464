
.container .unityWrapper .loadingBar {
    background: #f0eded;
    box-shadow: 0px 0px 20px 10px #ffffff59;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
    width: 300px;
    margin :  0px -150px;
    position: absolute;
    bottom: 40px;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .rotate {
    animation: rotation 2s infinite linear;
  }
  
  .container .unityWrapper .loadingBar .loadingBarFill {
    height: 10px;
    border-radius: 5px;
    margin: 2px;
    background:#4c93bd;
    transition: 0.3s width ease-out;
  }

  

.bar {
  min-height: 15px;
  border-radius: 5px;
  width: calc(113px * 4);
  @media only screen and (max-width: 450px) {
    width: calc(113px * 3);
  }
  @media only screen and (max-width: 350px) {
    width: calc(113px * 2);
  }
  box-shadow: 0px 10px 13px -6px rgba(44, 62, 80, 1);
  background-color: var(--colour2);
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 40px,
    var(--colour1) 40px,
    var(--colour1) 80px
  );

  animation: slide var(--speed) linear infinite;
  will-change: background-position;
}

  
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.container .unityWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}



.rpm-container
{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position:fixed;
  display:none;
  pointer-events: inherit;
}

.rpm-frame {
  width: 100%;
  height: 95%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
  Droid Sans, Helvetica Neue, sans-serif;
  font-size: 14px;
  border: none;
  display:block;
  pointer-events: inherit;
 
}

.rpm-hide-button {
  cursor: pointer;
  width: 100%;
  height: 5%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
  Droid Sans, Helvetica Neue, sans-serif;
  font-size: 14px;
  border: none;
  color:#000;
  background-color: #e2e3ec;
  padding: 0;
  margin: 0;
  box-shadow: inset 0px 0px 8px 2px rgba(0,0,0,0.2);
  pointer-events: inherit;
}

#rpm-hide-button:hover {
  background-color: #f1f2fa;
}

#rpm-hide-button:Active {
  background-color: #e2e3ec;
}

.warning {
  background-color: #b4b6c5;
  padding: 3px;
  border-radius: 5px;
  color: white;
}

#page-title {
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
  Droid Sans, Helvetica Neue, sans-serif;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  margin: auto;
}